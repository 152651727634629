.inner-layout {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 50% 50%;
	gap: 9px;
	height: 100%;
	&.login-layout {
		display: flex; 
		align-items: center;
		justify-content: center;
		position: relative;
		
		.form-wrapper {
			background: #000;
			padding: 45px 20px 55px;
			position: relative;
			z-index: 2;
			color: #fff;
			text-align: center;
			width: 100%;
			max-width: 530px;
			form {
				max-width: 290px;
				margin: 1em auto;
				// @media (min-width: 768px) {
				// 	margin: 3em auto 0;
				// }
			}
			input[type="email"],
			input[type="password"] {
				border: 1px solid #fff;
				background: #000;
				color: #fff;
				&::placeholder {
					color: #fff;
				}
			}
			button[type="submit"] {
				margin-top: 20px;
				background: var(--app--color--peach);
				display: block;
				width: 100%;
				padding: 10px;
				color: #000;
				font-family: var(--app--font--karbon-stencil);
			}
		}
		.reset-password {
			margin-top: 2.2em;
			font-size: 0.75rem; 
			display: block;
		}
	}
	&.admin-layout {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}
	&.dashboard-layout {
		grid-template-columns: 1fr 1fr;
		.layout-trace-profiles {
			grid-area: 2 / 1 / 3 / 2;
		}	
		.layout-tiles {	
			grid-row: span 2;
			overflow: auto;
			// .list {}
			&.dashboard-layout-trace-profiles {
				@media (max-width: 767px) {
					display: none;
				}
				grid-area: 1 / 2 / 2 / 3;
				@media (min-width: 768px) {
					grid-area: 2 / 1 / 3 / 2;
				}
			}
		}
	}
	&.files-layout {
		@media (max-width: 767px) {
			display: block;
			background-color: #fff;
			overflow: auto;
		}
		.quick-links {
			&.quick-links-left {
				li {
					@media (min-width: 768px) {
						display: none;
					}
				}
			}
			&.quick-links-right {
				display: none;
				@media (min-width: 768px) {
					display: flex;
				}
			}
		}
	}
	& > * {
		overflow: auto;
		&.layout-table {
			background: #fff;
			// padding: 10px 5px;
			padding: 10px 12px;
			position: relative;
			@media (min-width: 768px) {
				padding: 14px 18px;
			}
			&.orders-layout-table {
				grid-column: span 2;
				padding-bottom: 40px;
				@media (min-width: 768px) {
					grid-row: span 2;
					grid-column: span 1;
				}
			}
			&.dashboard-layout-table {
				@media (max-width: 767px) {
					grid-column: span 2;
				}
			}
		}
		&.layout-tiles {
			.list {
				--layout-tiles--height: calc(50% - 4px);
				display: grid;
				height: 100%;
				// Default to four or more
				
				grid-template-columns: 1fr 1fr;
				grid-auto-rows: minmax(var(--layout-tiles--height), 0);
				gap: 8px;
				h1, h2, h3, h4, h5, h6 {
					text-align: center;
				}

				&[data-items="1"] {
					grid-template-columns: 1fr;
					grid-template-rows: 100%;
				}
				&[data-items="2"] {
					grid-template-columns: 1fr;
					grid-template-rows: 50%;
				}
				&[data-items="3"] {
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 50%;
					& > * {
						&:last-child {
							grid-column: span 2;
						}
					}
				}
				&[data-items="1"],
				&[data-items="2"],
				&[data-items="3"] {
					@media (max-width: 767px) {
						grid-template-columns: 1fr;
					}
				}
				&[data-items="4"] {
					li.button,
					li.content {
						@media (max-width: 767px) {
							grid-column: span 2;
						}
					}
				}
				& > * {
					background: var(--app--color--grey);
					& > * {
						transition: 0.2s opacity;
					}
					&.video {
						position: relative;
						opacity: 0;
						transition: opacity 0.3s;
						&.is-visible {
							opacity: 1;
						}
						.video-wrapper {
							position: relative;
							height: 100%;
							label {
								z-index: 100;
								background: rgba(0, 0, 0, 0.8);
								position: absolute;
								bottom: 10px;
								left: 10px;
								padding: 7px 10px 5px;
								color: #fff;
								pointer-events: none;
							}
							button {
								@media (hover: hover) {
									transition: opacity 0.3s;
									&:hover {
										opacity: 0.6;
									}
								}
								&.play-video {
									position: absolute;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									z-index: 10;
									cursor: pointer;
								}
								&.close-video-modal {
									position: absolute;
									width: 22px;
									height: 36px;
									top: 20px;
									right: 30px;
									svg {
										fill: #fff;
									}
								}
							}
							img.video-placeholder {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								object-fit: cover;
								z-index: 5;
								width: 100%;
								height: 100%;
							}
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								z-index: 10;
							}

						}
						&.placeholder {
							svg {
								opacity: 0.5;
							}
						}
					}
					&.button,
					&.button a,
					&.button span {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						gap: var(--app--button--gap);
						width: 100%;
						height: 100%;
					}
					&.content {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					&.button {
						span,
						a {
							padding: 10px;
							// padding-bottom: 10px;
							@media (min-width: 768px) {
								padding: 20px;
								// padding-bottom: 20px;
							}
							* {
								position: relative;
								z-index: 10;
							}
							svg {
								max-width: 60%;
							}
							&::before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(246,227,211,1) 25%, rgba(236,194,160,1) 50%, rgba(226,165,115,1) 75%, rgba(218,160,109,1) 100%);
								z-index: 1;
								pointer-events: none;
								opacity: 0;
								transition: opacity 0.2s;
							}
							&:hover {
								&::before {
									opacity: 1;
								}
							}
						}
					}
				}
			}
			&.orders-layout-tiles {
				grid-column: span 2;
				@media (min-width: 768px) {
					grid-row: span 2;
					grid-column: span 1;
				}
			}
		}
	}
	.status {
		display: grid;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 20px);
    color: var(--app--color--grey-light);
    grid-template-rows: auto 1fr auto 1fr auto;
    align-items: end;
		overflow: hidden;
		max-height: 220px;
		@media (min-width: 768px) {
			margin: 30px 0;
			height: calc(100% - 60px);
		}
		li {
			display: grid;
			grid-template-columns: 1fr 18px 1fr;
			gap: 10px;
			position: relative;
			// align-items: center;
			padding: 0 10px;
			@media (min-width: 768px) {
				// align-items: start;
			}
			.circle {
				width: 18px;
				height: 18px;
				border: 3px solid;
				border-radius: 50%;
				background: var(--app--color--grey);
			}
			.label {
				font-family: var(--app--font--karbon-stencil);
				text-align: right;
				// font-size: 0.8rem;
				font-size: 0.9rem;
				line-height: 1.1em;
				@media (min-width: 768px) {
				}
				@media (min-width: 992px) {
					font-size: 1rem;
				}
			}
			svg {
				fill: var(--app--color--grey-light);
				max-width: 40%;
			}
			&.is-active {
				color: #000;
				svg {
					fill: #000;
				}
			}
			&:not(.line) {
				z-index: 5;	
			}
			&.line {
				z-index: 0;
				min-height: 2px;
				height: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				&::before {
					content: '';
					width: 3px;
					height: 1000%;
					background: var(--app--color--grey-light);
				}
				&.is-active {
					z-index: 2;
					&::before {
						background: #000;
					}
				}
			}
		}
	}
	&.is-loading {
		.layout-table {
			background: linear-gradient(135deg, rgba(255,255,255,1) 46%, rgba(200,200,200,1) 100%);
			background-size: 200% 200%;
			animation: gradient 2s ease infinite;
		}
		.layout-tiles {
			.list {
				& > * {
					background: linear-gradient(135deg, rgba(86,87,88,1) 46%, rgba(54,55,56,1) 100%);
					background-size: 200% 200%;
					animation: gradient 2s ease infinite;
					& > * {
						opacity: 0 !important;
					}
				}
			}
		}
	}
}



.layout-trace-profiles {
	background-color: var(--app--color--grey);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: var(--app--button--gap);
}

.orders-full-listing {
	color: var(--app--color--peach);
	text-transform: uppercase;
	font-family: var(--app--font--karbon-stencil);
	text-decoration: underline;
	font-size: var(--app--table--body--links--font-size);
	margin-left: 6px;
	transition: all 0.3s;
	opacity: 1;
	&:hover {
		text-decoration-color: transparent;
	}
	.is-loading & {
		opacity: 0;
	}
}

.quick-links {
	list-style: none;
	padding-left: 0;
	display: flex;
	gap: 7px;
	justify-content: flex-end;
	padding-right: 8px;
	transition: opacity 0.2s;
	min-height: 36px;
	.is-loading & {
		opacity: 0.25;
		pointer-events: none;
	}
	a {
		@media (hover: hover) {
			transition: opacity 0.2s;
			&:hover {
				opacity: 0.6;
			}
		}
	}
} 

.content-area {
	@media (min-width: 992px) {
		padding-right: 50px;
	}
	header {
		padding: 0;
		height: auto;
		img {
			margin: 0;
			justify-self: end;
			margin-right: 20px;
		}
	}
	* {
		margin-top: 1em;
		&:first-child {
			margin-top: 0;
		}
	}
	.two-columns {
		@media (min-width: 992px) {
			column-count: 2;
		}
	}
	
}

.content {
	&.grower-content {
		padding-bottom: 40px;
		p {
			line-height: 1.3em;
			margin: 1em 0;
		}
	}
}

.video-modal-outer {
	// @media (hover: hover) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 1000;
		padding: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		.video-modal-inner {
			width: 100%;
			aspect-ratio: calc(1.7777777777777777);
			max-height: 100%;
		}
	// }
}

.client-message  {
	font-family: var(--app--font--karbon-stencil);
	font-size: var(--app--navigation--font-size);
	text-transform: uppercase;
	&.loading {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.status-message {
	border: 2px solid green;
	padding: 16px 20px 13px;
	text-align: center;
	position: fixed;
	bottom: 20px;
	left: 40px;
	z-index: 100;
	background: #fff;
	pointer-events: none;
	opacity: 0;
	transition: 0.2s opacity;
	&.is-visible {
		opacity: 1;
	}
}

.checkbox-wrapper {
	display: flex;
	gap: 0px 20px;
	flex-wrap: wrap;
}

.files-list {
	& > * {
		margin-top: 12px;
		ul {
			display: flex;
			gap: 10px;
			margin-top: 8px;
			li {
				display: flex;
				flex-direction: column;
				gap: 5px;
				align-items: top;
				margin: 0;
				position: relative;
				// &:not(:first-child) {
				// 	margin-top: 5px;
				// }
				img {
					display: block;
					border: 1px solid #dddddd;
				}
				.delete-file {
					position: absolute;
					top: -6px;
					right: -6px;
					font-size: 0;
				}
			}
		}
		.buttons-span {
			.button {
				margin-top: 0;
				width: auto;
			}
		}
	}
}

.delete-file {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: #000;
	border-radius: 40px;
	position: relative;
	transition: background-color 0.2s;
	z-index: 1000;
	&::before,
	&::after {
		content: '';
		width: 10px;
		height: 2px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	&:hover {
		background: red;
	}
}

.key-value-list {
	td {
		padding: 0px 10px;
		border: 0 none;
		&::before {
			display: none;
		}
	}

}
.key-value-list-button {
	display: flex;
	align-items: center;
	gap: 7px;
	line-height: 20px;
	color: #000;
	cursor: pointer;
}

.enquiry-modal {
	.buttons-span {
		.button {
			margin-top: 0;
		}
	}
}

.back-wrapper {
	position: absolute;
	bottom: 50px;
	left: 30px;
	z-index: 100;
	button {
		font-family: var(--app--font--karbon-stencil);
    color: #000;
    background: var(--app--color--peach);
    font-size: var(--app--form--button--font-size);
    width: 100%;
    padding: 13px 10px 8px;
    text-transform: uppercase;
    min-width: 125px;
    text-align: center;
    line-height: 1em;
	}
}
@use "core/reset";
@use "core/type";
@use "core/images";
@use "core/upload";
@use "core/wysiwyg";

@use "layout/header";
@use "layout/main";
@use "layout/footer";
@use "layout/inner-layout";

@font-face {
	font-family: "KarbonSlabStencilTest-Bold";
	src: url('../fonts/KarbonSlabStencilTest-Bold.otf') format("opentype");
	// -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
@font-face {
	font-family: "KarbonTest-Regular";
	src: url('../fonts/KarbonTest-Regular.otf') format("opentype");
}

:root {
	font-size: 16px;

	--app--color--peach: #daa06d;
	--app--color--grey: #6d6e71;
	--app--color--grey-light: #868686;

	--app--font--karbon-regular: "KarbonTest-Regular", tahoma, sans-serif;
	--app--font--karbon-stencil: "KarbonSlabStencilTest-Bold", georgia, serif;

	--app--button--gap: 8px;

	--app--form--input--font-size: 0.9rem; // 9.6px  
	--app--form--label--font-size: 0.9rem; // 9.6px  
	--app--form--button--font-size: 1.1rem; // 12.8px  

	--app--navigation--font-size: 1.2rem;

	--app--table--header--font-size: 0.9rem;
	--app--table--body--links--font-size: 0.75rem;

	--app--header-height: 82px;

	--app--main--padding-bottom: 46px;
	--app--main--padding-horizontal: 18px;

	@media (min-width: 768px) {
		--app--button--gap: 16px;
		--app--header-height: 102px;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.Toastify {
	--toastify-font-family: "KarbonTest-Regular", tahoma, sans-serif;
}

// * {
// 	-webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

* {
	box-sizing: border-box;
}

body {
	background-color: #000;
	color: #fff;
	font-family: var(--app--font--karbon-regular);
}

.app-wrapper {
	display: grid;
	grid-template-rows: auto minmax(0, 1fr);
	height: var(--app-height, 100vh);
	&.home {
		grid-template-rows: 1fr auto 1fr;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background: url(../images/home.jpg) 50% 50%/cover no-repeat;
			// filter: blur(1.5px);
		}
		.content {
			margin-bottom: 0;
		}
	}
}
header {
	// height: var(--app--header-height);
	padding: 30px 22px 22px;
	display: grid;
	grid-template-columns: auto 1fr;
	position: relative;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	// &.no-auth {
	// 	position: sticky;
	// 	top: 0;
	// }
	a {
		color: #fff;
		text-decoration: none;
	}
	.site-logo {
		svg {
			fill: #fff;
			max-width: 40%;
			min-width: 130px;
			@media (min-width: 992px) {
				min-width: 200px;
			}
			.app-wrapper.home & {
				fill: #000;
			}
		}	
		h1 {
			display: none;
		}
	}
	.navigation-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@media (min-width: 992px) {
			padding-left: 24px;
			padding-right: 20px;
			// justify-content: space-between;
			justify-content: flex-end;
			gap: 50px;
		}
		& > * {
			.app-wrapper.home & {
				display: none;
			}
		}
		nav {
			ul {
				list-style: none;
				display: flex;
			}
			&.navigation {
				height: 100%;
				@media (max-width: 991px) {
					&:not(.mobile-navigation) {
						display: none;
					}
				}
				// &:not(.mobile-navigation) {
				// 	display: none;
				// 	@media (min-width: 992px) {
				// 		display: block;
				// 		.app-wrapper.home header & {
				// 			display: none;
				// 		}
				// 	}

				// }
				& > ul {
					gap: min(3.462vw, 42px); 
					height: 100%;
					align-items: flex-end;
					padding-left: 0;
					li {
						// position: relative;
						& > a, & > button {
							font-family: var(--app--font--karbon-stencil);
							font-size: var(--app--navigation--font-size);
							text-transform: uppercase;
							line-height: 1em;
							padding: 0 2px;
							border: 0 none;
							height: auto;
							border-bottom: 5px solid transparent;
							transition: border-bottom-color 0.2s;
							box-sizing: border-box;
							display: block;
							cursor: pointer;
							&.current-menu-item {
								// transform: translate(0, -50%);
								border-bottom-color: var(--app--color--peach);
							}
						}
						&:hover {
							& > a,
							& > button {
								border-bottom-color: var(--app--color--peach);
							}
						}
						// .sub-menu {
						// 	flex-direction: column;
						// 	flex-wrap: nowrap;
						// 	gap: 16px;
						// 	transition: opacity 0.3s;
						// 	li {
						// 		a {
						// 			background: #fff;
						// 			color: #000;
						// 			font-family: var(--app--font--karbon-stencil);
						// 			border-left: 1px solid #000;
						// 			border-bottom: 1px solid #000;
						// 			padding: 17px 0 10px 20px;
						// 			height: auto;
						// 		}
						// 	}
						// }
					}
				}
			}
			&.user {
				ul {
					gap: min(3.627vw, 44px);
					li {
						button, a {
							display: block;
							position: relative;
							&.mobile-navigation-trigger {
								width: 28px;
								height: 28px;
								position: relative;
								@media (min-width: 768px) {
									width: 34px;
									height: 34px;
								}
								&::before,
								&::after {
									content: '';
									width: 100%;
									height: 2px;
									background: #fff;
									position: absolute;
									top: 5px;
									left: 0;
									// transform-origin: center left;
									transition: all 0.3s;
								}
								&::after {
									top: auto;
									bottom: 5px;
								}
								span {
									width: 100%;
									height: 2px;
									background: #fff;
									display: block;
									transition: all 0.3s;
								}
								&.is-active {
									&::before {
										top: calc(50% - 1px);
										transform: rotate(45deg);
									}
									&::after {
										bottom: calc(50% - 1px);
										transform: rotate(-45deg);
									}
									span {
										opacity: 0;
									}
								}
							}
							svg, svg * {
								transition: all 0.2s;
							}
							svg {
								@media (max-width: 768px) {
									max-height: 26px;
									width: auto;
								}
							}
							&.current-menu-item {
								&::after {
									content: '';
									position: absolute;
									width: 54px;
									height: 54px;
									background: #fff;
									left: 50%;
									bottom: 0;
									transform: translate(-50%, 135%) rotate(45deg);
								}
							}
						} 
						.label {
							display: none;
						}
						&:hover {
							button, a {
								svg, svg * {
									fill: var(--app--color--peach);
								}
							}
						}
						&.mobile-navigation {
							@media (min-width: 992px) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.modal-popup {
	// @media (hover: hover) {
		position: absolute;
		z-index: 999;
		background: #fff;
		right: 18px; 
		width: calc(100% - 36px);
		top: 100%;
		padding: 20px 10px 10px;
		
		min-height: calc(50vh - ((var(--app--header-height) + var(--app--main--padding-bottom)) / 2));
		box-shadow: 0 8px 8px rgba(0, 0, 0, 0.65);
		@media (min-width: 768px) {
			padding: 30px 20px 20px;
		}
		@media (min-width: 992px) {
			width: calc(50% - 23px);
			padding: 40px 20px 20px;
		}
		&.buttons-list {
			display: flex;
			flex-direction: column;	
			gap: 16px;
		} 
		&.navigation-modal {
			background: #000;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 0;
			.mobile-navigation {
				// height: 100%;
				ul {
					display: flex;
					align-items: center;
					flex-direction: column;
					li {
						a {
							&.current-menu-item {
								&::after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		a,
		button {
			&.link {
				background: #fff;
				color: #000;
				font-family: var(--app--font--karbon-stencil);
				border-left: 1px solid #000;
				border-bottom: 1px solid #000;
				padding: 17px 0 10px 20px;
				height: auto;
				&::placeholder {
					color: #000;
				}
			}
		}
		p {
			color: #000;
			margin: 15px 0;
			font-size: 0.9rem;
		}
		form {
			& > * {
				&:first-child {
					margin-top: 0;
				}
			}
			label {
				margin-top: 16px;
				@media (min-width: 768px) {
					margin-top: 32px;
				}
			}
			input {
				background: #fff;
				color: #000;
				font-family: var(--app--font--karbon-stencil);
				border-left: 1px solid #000;
				border-bottom: 1px solid #000;
				padding: 17px 0 10px 20px;
				height: auto;
				&::placeholder {
					color: #000;
				}
			}
		}
		.buttons {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 15px;
			button {
				margin-top: 20px;
				text-transform: uppercase;
				font-size: 1rem;
				padding-top: 15px;
				padding-bottom: 10px;

				font-family: var(--app--font--karbon-stencil);
				color: #000;
				background: var(--app--color--peach);
				font-size: var(--app--form--button--font-size);
				width: 100%;
				margin-top: 30px;
				padding: 9px 10px 5px;
				&.current-menu-item {
					// transform: translate(0, -50%);
					border-bottom-color: var(--app--color--peach);
				}
				&:only-child {
					grid-column: span 2;
					text-align: left;
				}
			}
		}
	// }
}

h1, h2, h3, h4, h5, h6 {
	font-family: var(--app--font--karbon-stencil);
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 2rem;
	@media (min-width: 768px) {
		font-size: 2.64rem;  
	}
}
h3 {
	font-size: 1rem;  
	text-transform: uppercase;
	@media (min-width: 768px) {
		font-size: 1.27rem;  
	}
}

img,
svg {
	max-width: 100%;
	height: auto;
}

table {
	width: 100%;
	border-spacing: 6px;
	border-collapse: initial;
	margin-bottom: 8px;
	.admin-layout & {
		border-spacing: 0 6px;
	}
	th, td {
		&[name="link"] {
			width: 55px;
		}
		&[name="delete"] {
			width: 40px;
			svg {
				width: 40px;
			}
		}
		&[name="link"],
		&[name="delete"] {
			button {
				transition: opacity 0.2s;
				&:hover {
					opacity: 0.6;
				}
			}
		}
	}
	thead {
		tr {
			th {
				font-family: var(--app--font--karbon-stencil);
				font-size: var(--app--table--header--font-size);
				padding: 0px 10px 2px;
				
				&:not(:empty) {
					border-bottom: 5px solid;
				}
			}
		}
	}
	tbody {
		tr {
			th {
				font-family: var(--app--font--karbon-stencil);
				font-size: var(--app--table--header--font-size);
			}
			th,
			td {
				font-size: 0.825rem;
				padding: 20px 12px 6px 12px;
				position: relative;
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 6px;
					right: 0;
					bottom: 0;
					border-bottom: 1px solid;
					pointer-events: none;
				}
				&:first-child {
					&::before {
						border-left: 1px solid;
					}
				}
				&[name="link"] {
					a {
						font-family: var(--app--font--karbon-stencil);
						text-transform: uppercase;
						text-decoration: underline;
						font-size: var(--app--table--body--links--font-size);
						// text-decoration-thickness: 1px;
						@media (hover: hover) {
							transition: text-decoration-color 0.3s;
							&:hover {
								text-decoration-color: transparent;
							}
						}
					}
				}
				&[name="grower"] {
					max-width: 100px;
					span {
						display: block;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
				& > span {
					&:not(:last-child) {
						&::after {
							content: ", ";
						}
					}
				}
			}
		}
	}
	&.files {
		tbody {
			tr {
				td {
					a {
						@media (hover: hover) {
							transition: opacity 0.3s;
							&:hover {
								opacity: 0.6;
							}
						}
						&:not(.download) {
							svg {
								display: inline-block;
								width: 12px;
								height: 12px;
								transform: scale(300%, 300%);
								transform-origin: left center;
							}
						}
						&.download {
							svg {
								display: inline-block;
								margin-right: 5px;
								vertical-align: middle;
								&:not(:first-child) {
									margin-left: 10px;
								}
							}
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

form {
	position: relative;
	label {
		display: block;
		text-align: left;
		margin-top: 12px;
		font-family: var(--app--font--karbon-stencil);
    font-size: var(--app--table--header--font-size);
		line-height: 1.8em;
		em {
			font-style: italic;
			font-weight: normal;
		}
	}
	input {
		&:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
			border: 1px solid #000;
			background: #fff;
			color: #000;
			appearance: none;
			width: 100%;
			font-family: var(--app--font--karbon-regular);
			font-size: var(--app--form--input--font-size);
			height: 34px;
			padding: 0 5px;
			header & {
				border-top: 0 none;
				border-right: 0 none;
			}
			&[aria-invalid="true"] {
				border-color: #ff0032;
				&:focus {
					outline-color: #ff0032;
				}
			}
			&.label-input {
				width: 100px;
			}
		}
		&[type="checkbox"] {
			&.checkbox-slider {
				position: relative;
				width: 48px;
				height: 23px;
				&:checked {
					&::before {
						background-color: #2988fb;
					}
					&::after {
						left: 24px;
					}
				}
				&::before {
					content: '';
					width: 48px;
					height: 23px;
					background: #6d6e71;
					position: absolute;
					left: 0;
					transition: all 0.2s;
				}
				&::after {
					content: '';
					width: 21px;
					height: 17px;
					background: #fff;
					position: absolute;
					left: 3px;
					top: 3px;
					transition: all 0.2s;
				}
			}
		}

	}
	textarea {
		border: 1px solid #000;
		background: #fff;
		color: #000;
		appearance: none;
		width: 100%;
		font-family: var(--app--font--karbon-regular);
		font-size: var(--app--form--input--font-size);
		padding: 10px 5px;
		min-height: 100px;
		&.code {
			font-family: monospace;
			font-weight: 500;
		}
		&[aria-invalid="true"] {
			border-color: #ff0032;
			&:focus {
				outline-color: #ff0032;
			}
		}
	}
	select {
		width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
		font-family: var(--app--font--karbon-regular);
		border: 1px solid;
		option {
			height: 34px;
		}
		&[aria-invalid="true"] {
			border-color: #ff0032;
			&:focus {
				outline-color: #ff0032;
			}
		}
	}
	.form-message {
		position: absolute;
		top: -5px;
		left: 0;
		right: 0;
		transform: translate(0, -100%);
		color: var(--app--color--peach);
		&.error {
			color: red;
		}
	}
	.select-wrapper {
		// border: 1px solid;
		position: relative;
		&::after {
			content: '';
			width: 10px;
			height: 10px;
			border-left: 1px solid;
			border-bottom: 1px solid;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translate(0, -70%) rotate(315deg);
			pointer-events: none;
		}
	}
}
.buttons-span {
	display: flex;
	gap: 30px;
	margin-top: 24px;
	label {
		text-transform: uppercase;
	}
	button, .button {
		font-size: var(--app--table--header--font-size);
	}
	.submit-buttons {
		display: flex;
		align-items: flex-end;
		gap: 30px;
		margin-left: auto;
	}
	button, .button {
		font-family: var(--app--font--karbon-stencil);
		color: #000;
		background: var(--app--color--peach);
		font-size: var(--app--form--button--font-size);	
		width: 100%;
		padding: 13px 10px 8px;
		text-transform: uppercase;
		min-width: 125px;
		text-align: center;
		line-height: 1em;
		&.cancel {
			background: var(--app--color--grey-light);
			@media (hover: hover) {
				&:hover {
					background: var(--app--color--grey);
				}
			}
		}
		&.close {
			min-width: 0;
			width: 30px;
			height: 30px;
			font-size: 0.8rem;
			background: var(--app--color--grey-light);
			border-radius: 20px;
			padding: 3px 0 0;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		// .button > & {
		// 	background-color: red !important;
		// 	// text-align: center;
		// 	// padding-top: 12px;
		// 	// padding-bottom: 11px;
		// 	// background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(246, 227, 211) 25%, rgb(236, 194, 160) 50%, rgb(226, 165, 115) 75%, rgb(218, 160, 109) 100%);
		// 	&.dark {
		// 		background: #000;
		// 		color: #fff;
		// 	}
		// }
	}
	.button-file {
		input[type="file"] {
			display: none;
		}
	}
}

table + form {
	margin-top: 30px;
}

.gallery-items {
	display: flex;
	gap: 10px;
	.gallery-item {
		position: relative;
		.close {
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(25%, -25%);
			opacity: 0.8;
			transition: opacity 0.3s;
			@media (hover: hover) {
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}
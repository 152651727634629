.progress {
	min-height: 5px;
	.progress-bar {
		background: linear-gradient(270deg, rgba(62,62,62,1) 0%, rgba(0,32,78,1) 100%);
		height: 100%;
		width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
		// &[aria-valuenow="100"] {
		// 	background: #efefef;
		// }
	}
}

.upload-files {
	margin-top: 20px;
	display: grid;
	grid-template-rows: auto auto auto;
	row-gap: 4px;
	width: 125px;
	.buttons-span {
		margin-top: 0;
	}
}

.inline-file {
	display: flex;
	flex-direction: row;
	& > ul {
		li {
			position: relative;
		}
	}
	.upload-files {
		margin-top: 0;
		.buttons-span button, .buttons-span .button {
			margin-top: 0;
		}
	}
	.delete-file {
    position: absolute;
    top: -6px;
    right: -6px;
		font-size: 0;
	}

}

.file-url {
	// text-decoration: underline;
	// max-width: 250px;
	// text-overflow: ellipsis;
	// overflow: hidden;
	// text-align: right;
	font-size: 0;
	position: relative;
	width: 100px;
	height: 50px;
	display: block;
	text-align: center;
	&::before {
		content: "";
    width: 50px;
    height: 50px;
    background: url(../../images/file-icons/file-17.svg) 0 0/auto 100% no-repeat;
    display: inline-block;
		margin: 0 auto;
		transition: opacity 0.3s;
	}
	@media( hover: hover ) {
		&:hover {
			&::before {
				opacity: 0.7;
			}
		}
	}
	&[data-mime="application/pdf"]::before {
		background-image: url( ../../images/file-icons/file-50.svg );
	}
	&[data-mime="image/svg+xml"]::before {
		background-image: url( ../../images/file-icons/file-15.svg );
	}
	&[data-mime="text/csv"]::before {
		background-image: url( ../../images/file-icons/file-1.svg );
	}
	&[data-mime="text/xml"]::before,
	&[data-mime="application/xml"]::before {
		background-image: url( ../../images/file-icons/file-2.svg );
	}
	&[data-mime="text/plain"]::before {
		background-image: url( ../../images/file-icons/file-3.svg );
	}
	&[data-mime="application/vnd.ms-excel"]::before,
	&[data-mime="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]::before {
		background-image: url( ../../images/file-icons/file-4.svg );
	}
	&[data-mime="application/msword"]::before {
		background-image: url( ../../images/file-icons/file-8.svg );
	}
	// .png file-7.svg
	// .jpg file-6
}

.file-thumbnail {
	width: 50px;
	height: 50px;
	object-fit: cover;
	margin-left: auto;
	margin-right: auto;
}